import Input from "../../../components/base/Input/Input";

const DetailDataInformasi = ({ data }) => {
  return (
    <form className=" w-full flex flex-col">
      <div className="p-5 grid grid-cols-2 gap-4">
        <div className="w-full flex flex-col items-start justify-start gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pengalaman Gerejawi</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.pengalamanGerejawi}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Pendidikan Terakhir</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.pendidikanTerakhir}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Pekerjaan</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.pekerjaan}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Profesi</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.profesi}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Penguasaan Bahasa</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.penguasaanBahasa}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Riwayat Lain</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.riwayatLain}
              disabled
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pengalaman Organisasi</p>
            <Input
              name="pengalamanOrganisasi"
              type="text"
              value={data.pengalamanOrganisasi}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Jurusan</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.jurusan}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Kerja</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.tempatKerja}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Posisi Jabatan</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.posisiJabatan}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Kompetensi Skill</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.kompetensiSkill}
              disabled
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default DetailDataInformasi;
