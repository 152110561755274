import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";
import Pagination from "../../../components/base/Table/Pagination";
import Table from "../../../components/base/Table/Table";
import { GetKeluarga } from "../../../service/jemaatService/JemaatServices";
import { columnsKeluarga, rowsKeluarga } from "./utils";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { ROUTES } from "../../../constant/routeConstant";
import Loader from "../../../components/loader/Loader";

const DataKeluarga = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    docs: [],
  });
  const [load, setLoad] = useState(true);

  const getData = async (data) => {
    try {
      const response = await GetKeluarga({
        data: data,
      });
      setLoad(false);
      if (response.status) {
        setData(response.data);
      }
    } catch (err) {
      setLoad(false);
      toast.error("Terjadi kesalahan pada sistem");
    }
  };

  useEffect(() => {
    getData({ page: 1, limit: 10 });
  }, []);

  const handlePagination = (page) => {
    getData({ page: page, limit: 10 });
  };

  const searchData = (e) => {
    setData({ docs: [] });
    if (e.length > 0) {
      getData({ nama: e });
    } else {
      getData({ page: 1, limit: 10 });
    }
  };

  const buttonTambah = () => {
    let role = localStorage.getItem("role");
    if (role === "1") {
      return (
        <Button onClick={() => navigate(ROUTES.ADMIN.JEMAAT.ADD)}>
          Tambah Data Keluarga
        </Button>
      );
    }
  };
  return (
    <div className="max-w-[95%] p-5 flex flex-col gap-5 pb-[100px]">
      <div className="flex flex-row gap-3 justify-between flex-wrap">
        <Input
          placeholder={"Cari data keluarga"}
          className="w-[70%] lg:w-[300px] bg-white-100"
          onChange={(e) => searchData(e.target.value)}
          prefix={<AiOutlineSearch className="ml-2" />}
        />
        {buttonTambah()}
      </div>
      <div className="w-full">
        {!load && data?.data?.length > 0 ? (
          <>
            <Table
              columns={columnsKeluarga}
              rows={rowsKeluarga(data, navigate)}
              load={load}
            />
            <Pagination pagination={data} handlePagination={handlePagination} />
          </>
        ) : (
          <div className="w-full h-[40vh] flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataKeluarga;
