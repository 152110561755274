import axios from "axios";

const timeout = 30000;

const doLogin = (url, data, params) => {
  const method = "POST";
  // const headers = {'X-DEVICE': 'web'}

  return axios({ url, method, data, params, timeout });
};

const doGet = (token, url, data, params) => {
  const method = "GET";
  const headers = { Authorization: `Bearer ${token}` };

  return axios({ url, method, headers, params });
};

const doPost = (url, token, data, params) => {
  const method = "POST";
  const headers = { Authorization: `Bearer ${token}` };

  return axios({ url, method, headers, data, params });
};

const doPut = (url, token, data) => {
  const method = "PUT";
  const headers = { Authorization: `Bearer ${token}` };

  return axios({ url, method, headers, data });
};

const doDelete = (url, token, data) => {
  const method = "DELETE";
  const headers = { Authorization: token };

  return axios({ url, method, headers, data });
};

const doDownload = (url, token, data, params) => {
  const method = "POST";
  const headers = { Authorization: `Bearer ${token}` };

  return axios({ url, method, headers, data, params, responseType: 'blob' });
};

export { doLogin, doDelete, doGet, doPost, doPut, doDownload };
