export const sanitizeObject = (object, deleted) => {
  let newSanitizeObject = object;
  Object.keys(newSanitizeObject).forEach((key, index) => {
    if (
      newSanitizeObject[key] === null ||
      newSanitizeObject[key]?.length === 0
    ) {
      newSanitizeObject[key] = null;
    }
  });
  if (deleted !== null) {
    delete newSanitizeObject[deleted];
  }
  return newSanitizeObject;
};
