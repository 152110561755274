import moment from "moment";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";
import Tabs from "../../../components/tabs/Tabs";
import { ROUTES } from "../../../constant/routeConstant";
import DetailDataInformasi from "./DetailDataInformasi";
import DetailDataJemaat from "./DetailDataJemaat";
import DetailDataTanggalan from "./DetailDataTanggalan";

const DetailJemaat = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [tabsData, setTabsData] = useState([
    {
      title: "Jemaat",
      active: true,
    },
    {
      title: "Tanggalan",
      active: false,
    },
    {
      title: "Informasi",
      active: false,
    },
  ]);

  return (
    <div className="p-5">
      <p className="text-blue-500 text-[24px] font-semibold font-poppins mb-5">
        Detail Jemaat
      </p>
      <Tabs tabsData={tabsData} onClick={(payload) => setTabsData(payload)} />
      <div className="w-full bg-white-100 mt-5 mb-[50px] border rounded-lg">
        {tabsData.find((item) => item.title === "Jemaat").active && (
          <DetailDataJemaat data={state} />
        )}
        {tabsData.find((item) => item.title === "Tanggalan").active && (
          <DetailDataTanggalan data={state} />
        )}
        {tabsData.find((item) => item.title === "Informasi").active && (
          <DetailDataInformasi data={state} />
        )}
      </div>
    </div>
  );
};

export default DetailJemaat;
