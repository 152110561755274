import { doLogin } from "../../config/baseApi";
import { SERVER_URL } from "../../config/serverApi";

export const LoginAction = async ({ data }) => {
  let result = { status: false, data: {}, message: "" };
  try {
    let response = await doLogin(`${SERVER_URL}/api/login`, data, {});
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.error.message;
  }
  return result;
};
