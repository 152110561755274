import { ROUTES } from "./routeConstant";

import { BsFillPeopleFill, BsStack } from "react-icons/bs";
import { BiPurchaseTag } from "react-icons/bi";

export const MENU_DATA = [
  {
    title: "Dashboard",
    icon: <BsStack color="white" />,
    path: ROUTES.ADMIN.DASHBOARD,
    child: [
      {
        title: "Dashboard",
        path: ROUTES.ADMIN.DASHBOARD,
      },
      {
        title: "Data Keluarga",
        path: ROUTES.ADMIN.DATA_KELUARGA,
      },
      {
        title: "Data Induk",
        path: ROUTES.ADMIN.DATA_INDUK,
      },
    ],
  },
  {
    title: "Gerakan Pemuda",
    icon: <BsFillPeopleFill color="white" />,
    path: ROUTES.ADMIN.DATA_INDUK,
    child: [
      {
        title: "Gerakan Pemuda",
        path: "#",
      },
    ],
  },
  {
    title: "Asset Gereja",
    icon: <BiPurchaseTag color="white" />,
    path: ROUTES.ADMIN.DATA_KELUARGA,
    child: [
      {
        title: "Asset Gereja",
        path: "#",
      },
    ],
  },
];
