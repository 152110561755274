import Loader from "../../loader/Loader";

const Button = ({
  variant = "primary",
  className = "",
  type,
  onClick,
  children,
  loading,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`${
        variant === "primary"
          ? "bg-blue-500 text-white-100 font-bold p-2 rounded-lg flex items-center justify-center"
          : "bg-gray-200 text-black-100 border font-bold p-2 rounded-lg flex items-center justify-center"
      } ${className} h-[50px] font-poppins`}
    >
      {loading ? <Loader show={true} /> : children}
    </button>
  );
};
export default Button;
