import Input from "../../../components/base/Input/Input";
import SelectInput from "../../../components/base/Input/SelectInput";

const EditDataJemaat = ({ data, handleChange }) => {
  const PelkatOptions = [
    {
      value: "",
      label: "Pilih Pelkat",
    },
    {
      value: "GP",
      label: "Gerakan Pemuda (GP)",
    },
    {
      value: "PKP",
      label: "Persekutuan Kaum Perempuan (PKP)",
    },
    {
      value: "PKB",
      label: "Persekutuan Kaum Bapak (PKB)",
    },
    {
      value: "PA",
      label: "Pelayanan Anak",
    },
    {
      value: "PT",
      label: "Persekutuan Teruna",
    },
    {
      value: "PKLU",
      label: "Persekutuan Kaum Lanjut Usia",
    },
  ];

  const SektorOptions = [
    {
      value: "",
      label: "Pilih Sektor",
    },
    {
      value: "Yerusalem",
      label: "Yerusalem",
    },
    {
      value: "Betel",
      label: "Betel",
    },
    {
      value: "Hermon",
      label: "Hermon",
    },
    {
      value: "Sion",
      label: "Sion",
    },
    {
      value: "Yerikho",
      label: "Yerikho",
    },
    {
      value: "Karmel",
      label: "Karmel",
    },
    {
      value: "Pniel",
      label: "Pniel",
    },
    {
      value: "Nazaret",
      label: "Nazaret",
    },
    {
      value: "Moria",
      label: "Moria",
    },
    {
      value: "Getsemani",
      label: "Getsemani",
    },
    {
      value: "Bethesda",
      label: "Bethesda",
    },
    {
      value: "Betlehem",
      label: "Betlehem",
    },
    {
      value: "Ebenhaezer",
      label: "Ebenhaezer",
    },
  ];

  return (
    <div className=" w-full flex flex-col">

      <div className="w-full flex flex-col items-center justify-center gap-[20px] p-[40px]">
        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Nama Pertama</p>
            <Input
              name="namaPertama"
              type="text"
              onChange={handleChange}
              value={data.namaPertama}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Nama Belakang</p>
            <Input
              name="namaBelakang"
              type="text"
              value={data.namaBelakang}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Jenis Kelamin</p>
            <SelectInput
              option={[
                { value: "l", label: "Laki-Laki" },
                { value: "p", label: "Perempuan" },
              ]}
              name="jenisKelamin"
              value={data.jenisKelamin}
              onChange={handleChange}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Golongan Darah</p>
            <Input
              name="golonganDarah"
              type="text"
              onChange={handleChange}
              value={data.golonganDarah}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">No Telp</p>
            <Input
              name="noTelp"
              type="text"
              onChange={handleChange}
              value={data.noTelp}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Email</p>
            <Input
              name="email"
              type="text"
              value={data.email}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Alamat</p>
            <Input
              name="alamat"
              type="text"
              onChange={handleChange}
              value={"-"}
              placeholder="Fitur sedang dikerjakan"
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Sektor</p>
            <SelectInput
              option={SektorOptions}
              name="sektor"
              onChange={handleChange}
              value={data.sektor}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pelkat</p>
            <SelectInput
              name="statusPelkat"
              option={PelkatOptions}
              onChange={handleChange}
              value={data.statusPelkat}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Status</p>
            <Input
              name="status"
              type="text"
              disabled={true}
              value={data.statusAktif}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default EditDataJemaat;
