import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

const MasterLayout = ({ children }) => {
  return (
    <div className="w-full h-full flex flex-row">
      <Sidebar />
      <div className="bg-gray-100 w-full">
        <Navbar />
        <div className="overflow-x-hidden overflow-y-auto max-h-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MasterLayout;
