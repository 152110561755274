import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HeroLogin, KeyLock, Profile, WebNameLogo } from "../../../assets";
import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";
import { ROUTES } from "../../../constant/routeConstant";
import { LoginAction } from "../../../service/login/LoginService";
import jwt from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await LoginAction({ data: formData });
      console.log(response);
      if (response.status) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.user.roleId);
        localStorage.setItem("name", response.data.user.name);
        navigate(ROUTES.ADMIN.DASHBOARD);
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      toast.error("Periksa kembali username dan password anda");
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full bg-white flex flex-row items-center justify-center">
      <div className="lg:w-[55%] w-[90%] h-full flex flex-col items-start justify-center lg:pl-[100px]">
        <div className="flex flex-row items-start justify-start">
          <img src={WebNameLogo} alt="web-name-logo" className="mb-[50px]" />
        </div>
        <div className="flex flex-col items-start justify-start mb-[40px] lg:pl-6">
          <p className="font-poppins text-blue-500 text-[24px] font-bold ">
            Login to your account
          </p>
          <p className="font-poppins text-blue-500 text-[14px] font-light ">
            Welcome Back! Please fill your username and password
          </p>
        </div>
        <form className="lg:pl-6" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-[24px] items-center justify-center lg:w-[400px]">
            <Input
              placeholder={"Username"}
              prefix={<img src={Profile} alt="username" className="mx-3" />}
              onChange={handleChange}
              name="username"
              value={formData.username}
            />
            <Input
              placeholder={"Password"}
              prefix={<img src={KeyLock} alt="password" className="mx-3" />}
              type="password"
              name="password"
              onChange={handleChange}
              value={formData.password}
            />
          </div>
          <Button className="w-full mt-[45px]" type="submit">
            LOGIN
          </Button>
        </form>
        <div className="absolute bottom-10 left-[20%] flex flex-col items-center justify-center text-gray-400 font-semibold">
          <p>By Inforkom Team</p>
          <p>GPIB Pancaran Kasih Depok</p>
        </div>
      </div>
      <div className="lg:w-[45%] h-full w-0 ">
        <img
          src={HeroLogin}
          alt="hero-login"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
};
export default Login;
