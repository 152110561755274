const Input = ({
  children,
  placeholder,
  className,
  inputClassName,
  prefix,
  suffix,
  onChange,
  type,
  name,
  ...props
}) => {
  return (
    <div
      className={`p-1 ${
        props.disabled ? "bg-gray-100" : "border-2 border-gray-100"
      }  rounded-md ${className} w-full flex flex-row items-center justify-start`}
    >
      {prefix !== null && <div>{prefix}</div>}
      <input
        name={name}
        className={`${inputClassName} bg-none outline-none border-none h-[40px] ml-3 ${
          props.disabled && "text-[gray]"
        }`}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        style={{ background: "none", width: type === "date" ? "95%" : "80%" }}
        {...props}
      >
        {children}
      </input>
      {suffix !== null && <div>{suffix}</div>}
    </div>
  );
};

export default Input;
