import moment from "moment";
import Input from "../../../components/base/Input/Input";

const EditDataTanggalan = ({ data, handleChange }) => {
  return (
    <form className=" w-full flex flex-col">

      <div className="w-full flex flex-col items-center justify-center gap-[20px] p-[40px]">
        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Lahir</p>
            <Input
              onChange={handleChange}
              name="tempatLahir"
              type="text"
              value={data.tempatLahir}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Lahir</p>
            <Input
              onChange={handleChange}
              name="tanggalLahir"
              type="date"
              value={
                data.tanggalLahir === "0000-00-00 00:00:00"
                  ? null
                  : moment(data.tanggalLahir).format("YYYY-MM-DD")
              }
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Baptis</p>
            <Input
              onChange={handleChange}
              name="tempatBaptis"
              type="text"
              value={data.tempatBaptis}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Baptis</p>
            <Input
              onChange={handleChange}
              name="tanggalBaptis"
              type="date"
              value={
                data.tanggalBaptis === "0000-00-00 00:00:00"
                  ? null
                  : moment(data.tanggalBaptis).format("YYYY-MM-DD")
              }
            />
          </div>


        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Sidi</p>
            <Input
              onChange={handleChange}
              name="tempatSidi"
              type="text"
              value={data.tempatSidi}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Sidi</p>
            <Input
              onChange={handleChange}
              name="tanggalSidi"
              type="date"
              value={
                data.tanggalSidi === "0000-00-00 00:00:00"
                  ? null
                  : moment(data.tanggalSidi).format("YYYY-MM-DD")
              }
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Nikah Gereja</p>
            <Input
              onChange={handleChange}
              name="tglNikahGereja"
              type="date"
              value={
                data.tglNikahGereja === "0000-00-00 00:00:00"
                  ? null
                  : moment(data.tglNikahGereja).format("YYYY-MM-DD")
              }
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Nikah Sipil</p>
            <Input
              onChange={handleChange}
              name="tglNikahSipil"
              type="date"
              value={
                data.tglNikahSipil === "0000-00-00 00:00:00"
                  ? null
                  : moment(data.tglNikahSipil).format("YYYY-MM-DD")
              }
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditDataTanggalan;
