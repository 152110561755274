import { BiChevronRightCircle, BiEdit, BiTrash } from "react-icons/bi";
import Pil from "../../../components/pil/Pil";
import { ROUTES } from "../../../constant/routeConstant";

export const columns = [
  { label: "ID", field: "id" },
  { label: "No Induk", field: "noInduk" },
  { label: "Nama", field: "nama" },
  { label: "Hubungan", field: "hubunganKeluarga" },
  { label: "Pelkat", field: "pelkat" },
  { label: "Sektor", field: "sektor" },
  { label: "Action", field: "action" },
];

export const rows = (data, navigate) => {
  const role = localStorage.getItem("role");
  return (
    data.data &&
    data.data.map((item, index) => ({
      key: index,
      id: item.id,
      noInduk: item.noInduk,
      nama: `${item.namaPertama} ${item.namaBelakang}`,
      hubunganKeluarga: item.hubunganKeluarga,
      pelkat:
        item.statusPelkat !== null ? (
          item.statusPelkat
        ) : (
          <Pil label="Mohon di isi" variant={"danger"} />
        ),
      sektor: item.sektor,
      statusAktif: (
        <Pil
          label={item.statusAktif}
          variant={item.statusAktif === "Aktif" ? "success" : "danger"}
        />
      ),
      action: (
        <div className="flex flex-row items-center justify-center gap-[10px]">
          {role === "1" && (
            <>
              <button
                className=" hover:bg-blue-100 hover:text-white-100 text-black-100 p-1 bg-gray-200 rounded-xl"
                onClick={() =>
                  navigate(ROUTES.ADMIN.JEMAAT.EDIT, { state: item })
                }
              >
                <BiEdit />
              </button>
              <button
                className=" hover:bg-blue-100 hover:text-white-100 text-black-100 p-1 bg-gray-200 rounded-xl"
                onClick={() =>
                  navigate(ROUTES.ADMIN.JEMAAT.DETAIL, { state: item })
                }
              >
                <BiTrash />
              </button>
            </>
          )}
          <button
            className=" hover:bg-blue-100 hover:text-white-100 text-black-100 p-1 bg-gray-200 rounded-xl"
            onClick={() =>
              navigate(ROUTES.ADMIN.JEMAAT.DETAIL, { state: item })
            }
          >
            <BiChevronRightCircle fontSize="18px" />
          </button>
        </div>
      ),
    }))
  );
};
