import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/base/Button/Button";
import Table from "../../../components/base/Table/Table";
import Loader from "../../../components/loader/Loader";
import Tabs from "../../../components/tabs/Tabs";
import { ROUTES } from "../../../constant/routeConstant";
import { GenerateKwj, GetDetailKeluarga } from "../../../service/jemaatService/JemaatServices";
import { columns, rows } from "./utils";
import { IoMdDownload } from "react-icons/io";

import { FaArrowAltCircleLeft } from "react-icons/fa";


const DetailKeluarga = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ status: false, data: [] });
  const [isDownload, setIsDownload] = useState(false)

  const [tabsData, setTabsData] = useState([
    {
      title: "Informasi Keluarga",
      active: true,
    },
  ]);

  const getData = async () => {
    const response = await GetDetailKeluarga(state);
    if (response.status) {
      setFormData(response.data);
    } else {
      toast.error("Error!");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const downloadKwj = () => {

  }

  const buttonTambah = () => {
    let role = localStorage.getItem("role");
    if (role === "1") {
      return (
        <Button
          onClick={() =>
            navigate(ROUTES.ADMIN.JEMAAT.TAMBAH_KELUARGA, {
              state: { ...state, ...formData },
            })
          }
        >
          Tambah Anggota Keluarga
        </Button>
      );
    }
  };

  return (
    <div className="p-5">
      <p className="text-blue-500 text-[24px] font-semibold font-poppins mb-5 flex flex-row items-center gap-[10px]">
        <span>
          <FaArrowAltCircleLeft className="cursor-pointer" onClick={() => navigate(-1)} />
        </span>
        Detail Keluarga
      </p>
      <div className="flex flex-row gap-3 justify-between flex-wrap">
        <Tabs tabsData={tabsData} onClick={(payload) => setTabsData(payload)} />
        <div className="flex gap-3">
          <Button
            onClick={() => GenerateKwj({
              data: {
                kodeKeluarga: state.kodeKeluarga
              }
            })
            }
            className="bg-red-100"
          >
            Download KWJ
            <IoMdDownload className="mx-2" />
          </Button>
          {buttonTambah()}
        </div>
      </div>
      <div className="w-full bg-white-100 mt-5 mb-[50px] border rounded-lg">
        <div className="w-full">
          {formData.data.length > 0 ? (
            <>
              <Table columns={columns} rows={rows(formData, navigate)} />
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailKeluarga;
