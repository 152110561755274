import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { toast } from "react-toastify";
import SmallCard from "../../../components/smallcard/SmallCard";
import { GetDashboard } from "../../../service/dashboardService/DashboardService";

ChartJS.register(ArcElement, Tooltip, Legend);
const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const data = {
    labels: dashboardData?.sektor?.map((item) => {
      return item.sektor;
    }),
    datasets: [
      {
        label: "Jumlah Jemaat",
        data: dashboardData?.sektor?.map((item) => {
          return item.count;
        }),
        backgroundColor: [
          "#F7CAC9",
          "#F4AFAF",
          "#FFC3A0",
          "#FFE5B4",
          "#FFF2B5",
          "#D9E8A2",
          "#B7DFD5",
          "#B2EBF4",
          "#B5EAEA",
          "#D5C7E8",
          "#F5CBDC",
          "#E9B7B7",
          "#E0BBE4",
        ],
        borderColor: [
          "#F7CAC9",
          "#F4AFAF",
          "#FFC3A0",
          "#FFE5B4",
          "#FFF2B5",
          "#D9E8A2",
          "#B7DFD5",
          "#B2EBF4",
          "#B5EAEA",
          "#D5C7E8",
          "#F5CBDC",
          "#E9B7B7",
          "#E0BBE4",
        ],
        borderWidth: 1,
      },
    ],
  };

  const getDashboardData = async () => {
    let response = await GetDashboard();
    if (response?.status) {
      setDashboardData(response?.data);
    } else {
      toast.error(response?.message);
    }
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="w-full p-5">
      <div className="w-full flex rounded-lg border bg-primary">
        <div className="w-[500px] py-5">
          <Doughnut data={data} />
        </div>
        <div className="w-full  flex items-start justify-start flex-col p-5 ">
          <p className="font-bold text-[24px] text-blue-500">
            Data Jemaat Per Sektor
          </p>
          <div className="flex items-center justify-start flex-wrap gap-3 mt-5">
            {dashboardData?.sektor?.map((item, index) => {
              return (
                <SmallCard
                  title={item.sektor}
                  data={item.count}
                  color={data.datasets[0].backgroundColor[index]}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-between flex-wrap mt-5">
        <div className="w-[100%] lg:w-[33%] h-[400px] rounded border bg-primary flex flex-col items-center justify-start">
          <p className="font-bold text-[24px] text-blue-500 my-5">
            Data Jemaat Per Gender
          </p>
          <div className="w-full flex items-center justify-center gap-5 px-5">
            <div className="w-[40%] rounded">
              <p className="w-full items-start justify-start rounded bg-blue-500 text-white-100 p-3">
                Laki-Laki
              </p>
              <p className="w-full items-start justify-start rounded  text-blue-500 font-bold p-3">
                {
                  dashboardData?.gender?.filter(
                    (item) => item.jenisKelamin === "l"
                  )[0].count
                }
              </p>
            </div>
            <div className="w-[40%] rounded">
              <p className="w-full items-start justify-start rounded bg-blue-500 text-white-100 p-3">
                Perempuan
              </p>
              <p className="w-full items-start justify-start rounded  text-blue-500 font-bold p-3">
                {
                  dashboardData?.gender?.filter(
                    (item) => item.jenisKelamin === "p"
                  )[0].count
                }
              </p>
            </div>
          </div>
        </div>
        <div className="-[100%] lg:w-[33%] h-[400px] rounded border bg-primary flex flex-col items-center justify-start">
          <p className="font-bold text-[24px] text-blue-500 my-5">
            Data Jemaat Per Pelkat
          </p>
          <div className="w-full flex items-center justify-center gap-5 px-5 flex-wrap">
            {dashboardData?.pelkat?.map((item) => {
              return (
                <div className="w-[40%] rounded">
                  <p className="w-full items-start justify-start rounded bg-blue-500 text-white-100 p-3">
                    {item.statusPelkat === null
                      ? "Belum update"
                      : item.statusPelkat}
                  </p>
                  <p className="w-full items-start justify-start rounded  text-blue-500 font-bold p-3">
                    {item.count}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-[100%] lg:w-[33%] h-[400px] rounded border bg-primary  flex flex-col items-center justify-start">
          <p className="font-bold text-[24px] text-blue-500 my-5">
            Data Jemaat Sudah terupdate
          </p>
          <div className="w-full flex items-center justify-center gap-5 px-5 flex-wrap">
            <div className="w-[40%] rounded">
              <p className="w-full items-start justify-start rounded bg-blue-500 text-white-100 p-3">
                In Progress
              </p>
              <p className="w-full items-start justify-start rounded  text-blue-500 font-bold p-3">
                {dashboardData?.updated?.null_count}
              </p>
            </div>
            <div className="w-[40%] rounded">
              <p className="w-full items-start justify-start rounded bg-blue-500 text-white-100 p-3">
                Total Data
              </p>
              <p className="w-full items-start justify-start rounded  text-blue-500 font-bold p-3">
                {parseInt(dashboardData?.updated?.not_null_count) + parseInt(dashboardData?.updated?.null_count)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
