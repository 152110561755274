import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoGpib } from "../../../assets";
import { MENU_DATA } from "../../../constant/menuConstant";

const Sidebar = ({ className }) => {
  const navigate = useNavigate();
  const path = useLocation();
  const [open, setOpen] = useState(false);
  const [childMenu, setChildMenu] = useState([]);

  return (
    <div
      className={`${open
        ? "min-w-[300px] transition ease-in-out delay-100"
        : "min-w-fit max-w-[300px]"
        }  bg-white shadow-lg hidden lg:block z-10 flex-col pt-5 bg-blue-500 `}
    >
      <div className="w-full bg-white-100 mt-[-20px]">
        <img
          src={LogoGpib}
          alt="gpib-pk"
          className="w-[50px] m-auto mt-[-10px]"
        />
      </div>
      <div className="flex h-full">
        <div className="w-fit flex flex-col items-start justify-start mt-2 gap-2 ">
          {MENU_DATA.map((item) => {
            return (
              <button
                className="w-full font-bold hover:bg-blue-100 p-5"
                onClick={() => {
                  setOpen(true);
                  if (item.child) {
                    setChildMenu(item.child);
                  } else {
                    setChildMenu([]);
                  }
                }}
              >
                {item.icon}
              </button>
            );
          })}
        </div>
        {open && (
          <div className="flex-grow bg-white-100 flex flex-col items-start justify-start gap-2 ">
            {childMenu.map((item) => {
              return (
                <button
                  className={`w-full text-[16px] ${path.pathname.includes(item.path) ? 'bg-blue-100 text-white-100' : ''} hover:bg-blue-100 hover:text-white-100 text-blue-500 font-bold p-5 text-left transition ease-in-out`}
                  onClick={() => navigate(item.path)}
                >
                  {item.title}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
