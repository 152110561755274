const Stepper = ({ menus }) => {
  return (
    <div className="p-5 flex flex-row items-start justify-start gap-[20px]">
      {menus.map((item, index) => {
        return (
          <div
            className="flex flex-row items-center justify-start gap-[10px]"
            key={index}
          >
            <div
              className={`rounded-[50%] w-[30px] h-[30px] ${
                item.status === "open" && "bg-blue-500"
              } ${item.status === "closed" && "bg-gray-300"} ${
                item.status === "completed" && "bg-green-200"
              }`}
            ></div>
            <p
              className={`${item.status === "open" && "text-blue-500"} ${
                item.status === "closed" && "text-gray-300"
              } ${item.status === "completed" && "text-green-200"} font-bold`}
            >
              {item.title}
            </p>
            {index + 1 !== menus.length && (
              <div className="h-[2px] w-[100px] bg-gray-300 "></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
