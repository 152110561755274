import { doDownload, doGet, doPost, doPut } from "../../config/baseApi";
import { SERVER_URL } from "../../config/serverApi";

export const GenerateKwj = async ({ data }) => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doDownload(`${SERVER_URL}/admin/generate-pdf`, token, data, {});
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `kwj-${data.kodeKeluarga}.pdf`); // Change 'file_name.pdf' to your desired file name
    document.body.appendChild(link);
    link.click();
    result = {
      status: true,
      data: '',
      message: 'Success Download',
    }
  } catch (err) {
    console.log(err, 'error nih')
    // result.message = err.response.data.error.message;
  }
  return result;
};

export const DeleteJemaat = async (data, id) => {
  let result = { status: false, data: data, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doPut(`${SERVER_URL}/admin/jemaat/${id}`, token, null);
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.error.message;
  }
  return result;
};
export const GetJemaat = async ({ data }) => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doGet(token, `${SERVER_URL}/admin/jemaat`, data, data);
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.error.message;
  }
  return result;
};

export const GetKeluarga = async ({ data }) => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doGet(
      token,
      `${SERVER_URL}/admin/keluarga/jemaat`,
      data,
      data
    );
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.error.message;
  }
  return result;
};

export const GetDetailKeluarga = async (data) => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doGet(
      token,
      `${SERVER_URL}/admin/keluarga/detail/jemaat`,
      data,
      data
    );
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.error.message;
  }
  return result;
};

export const PostKeluarga = async (data) => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doPost(`${SERVER_URL}/admin/jemaat`, token, data, {});
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.error.message;
  }
  return result;
};

export const PostAnggotaKeluarga = async (data) => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doPost(`${SERVER_URL}/admin/jemaat`, token, data, {});
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.message;
  }
  return result;
};

export const PutKeluarga = async (data, id) => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doPut(
      `${SERVER_URL}/admin/jemaat/${data.id}`,
      token,
      data
    );
    if (response.data.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response.data.message,
      };
    }
  } catch (err) {
    result.message = err.response.data.error.message;
  }
  return result;
};
