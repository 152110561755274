import { doGet } from "../../config/baseApi";
import { SERVER_URL } from "../../config/serverApi";

export const GetDashboard = async () => {
  let result = { status: false, data: {}, message: "" };
  let token = localStorage.getItem("token");
  try {
    let response = await doGet(token, `${SERVER_URL}/admin/dashboard`, {}, {});
    if (response?.data?.code === 200) {
      result = {
        status: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      result = {
        status: false,
        message: response?.data?.message,
      };
    }
  } catch (err) {
    if (result.message) {
      result.message = err.response.data.error.message;
    }
  }
  return result;
};
