const SelectInput = ({ option, ...props }) => {
  return (
    <div
      className={`w-full ${
        props.disabled ? "bg-gray-100" : "bg-white-100 border-2 border-gray-100"
      }  rounded-md p-1`}
    >
      <select
        {...props}
        className={`${
          props.disabled
            ? "bg-gray-100"
            : "bg-white-100 border-none outline-none"
        }  rounded-md w-[95%] h-[40px] ml-2 mr-2 `}
      >
        {option.map((item, index) => {
          return <option value={item.value}>{item.label}</option>;
        })}
      </select>
    </div>
  );
};

export default SelectInput;
