const Pil = ({ label, variant }) => {
  const checkVariant = () => {
    if (variant === "danger") {
      return "text-red-300 bg-red-200";
    }
    if (variant === "success") {
      return "text-green-200 bg-green-100";
    }
  };
  return (
    <div
      className={`font-poppins font-semibold px-5 rounded-xl ${checkVariant()}`}
    >
      {label}
    </div>
  );
};

export default Pil;
