import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";
import SelectInput from "../../../components/base/Input/SelectInput";

const FormTambahJemaat = ({
  onSubmit,
  formData,
  handleChange,
  isTambahKeluarga = false,
  kodeKeluarga,
  lastChild = "03",
  changeStatusKeluarga,
}) => {
  const StatusKeluargaOptions = [
    {
      value: "",
      label: "Pilih Status Keluarga",
    },
    {
      value: "02",
      label: "Istri",
    },
    {
      value: `0${lastChild + 1}`,
      label: "Anak",
    },
    {
      value: "08",
      label: "Lainnya",
    },
  ];
  const PelkatOptions = [
    {
      value: "",
      label: "Pilih Pelkat",
    },
    {
      value: "GP",
      label: "Gerakan Pemuda (GP)",
    },
    {
      value: "PKP",
      label: "Persekutuan Kaum Perempuan (PKP)",
    },
    {
      value: "PKB",
      label: "Persekutuan Kaum Bapak (PKB)",
    },
    {
      value: "PA",
      label: "Pelayanan Anak",
    },
    {
      value: "PT",
      label: "Persekutuan Teruna",
    },
    {
      value: "PKLU",
      label: "Persekutuan Kaum Lanjut Usia",
    },
  ];

  const SektorOptions = [
    {
      value: "",
      label: "Pilih Sektor",
    },
    {
      value: "Yerusalem",
      label: "Yerusalem",
    },
    {
      value: "Betel",
      label: "Betel",
    },
    {
      value: "Hermon",
      label: "Hermon",
    },
    {
      value: "Sion",
      label: "Sion",
    },
    {
      value: "Yerikho",
      label: "Yerikho",
    },
    {
      value: "Karmel",
      label: "Karmel",
    },
    {
      value: "Pniel",
      label: "Pniel",
    },
    {
      value: "Nazaret",
      label: "Nazaret",
    },
    {
      value: "Moria",
      label: "Moria",
    },
    {
      value: "Getsemani",
      label: "Getsemani",
    },
    {
      value: "Bethesda",
      label: "Bethesda",
    },
    {
      value: "Betlehem",
      label: "Betlehem",
    },
    {
      value: "Ebenhaezer",
      label: "Ebenhaezer",
    },
  ];

  return (
    <form className=" w-full flex flex-col" onSubmit={onSubmit}>

      {isTambahKeluarga && (
        <div className="w-[95%] p-5">
          <p className="font-semibold">Kode Keluarga</p>
          <Input
            name="kodeKeluarga"
            type="text"
            disabled
            value={kodeKeluarga}
          />
        </div>
      )}

      <div className="flex flex-col items-center justify-center w-full -mt-[40px] p-[40px] gap-[20px]">

        {isTambahKeluarga && (
          <div className="flex flex-row w-full gap-[20px]">
            <div className="w-[90%]">
              <p className="font-semibold">Kode Induk</p>
              <Input
                name="kodeInduk"
                type="text"
                value={formData.noInduk}
                disabled
              />
              <p className="italic text-[12px] mb-[-10px]">
                *Notes: Pilih status keluarga terlebih dahulu
              </p>
            </div>
            <div className="w-[90%]">
              <p className="font-semibold">Status Keluarga</p>
              <SelectInput
                option={StatusKeluargaOptions}
                name="statusKeluarga"
                onChange={changeStatusKeluarga}
                value={formData.statusKeluarga}
                required
              />
            </div>
          </div>
        )}

        <div className="flex flex-row w-full gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Nama Pertama</p>
            <Input
              name="namaPertama"
              type="text"
              onChange={handleChange}
              value={formData.namaPertama}
              required
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Nama Belakang</p>
            <Input
              name="namaBelakang"
              type="text"
              onChange={handleChange}
              value={formData.namaBelakang}
              required
            />
          </div>
        </div>

        <div className="flex flex-row w-full gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Jenis Kelamin</p>
            <SelectInput
              option={[
                { value: "l", label: "Laki-Laki" },
                { value: "p", label: "Perempuan" },
              ]}
              onChange={handleChange}
              name="jenisKelamin"
              value={formData.jenisKelamin}
              required
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Golongan Darah</p>
            <Input
              name="golonganDarah"
              type="text"
              onChange={handleChange}
              value={formData.golonganDarah}
            />
          </div>

        </div>

        <div className="flex flex-row w-full gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">No Telp</p>
            <Input
              name="noTelp"
              type="text"
              onChange={handleChange}
              value={formData.noTelp}
              required
            />
            {isTambahKeluarga && (
              <p className="italic text-[12px] mb-[-10px]">
                *Notes: Jika masih anak-anak dicantumkan No Telp orang tua
              </p>
            )}
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Email</p>
            <Input
              name="email"
              type="text"
              onChange={handleChange}
              value={formData.email}
            />
          </div>
        </div>

        <div className="flex flex-row w-full gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Alamat</p>
            <Input
              name="alamat"
              type="text"
              disabled
              placeholder="Fitur sedang dikerjakan"
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Sektor</p>
            <SelectInput
              option={SektorOptions}
              name="sektor"
              onChange={handleChange}
              value={formData.sektor}
              disabled={isTambahKeluarga}
              required
            />
          </div>
        </div>

        <div className="flex flex-row w-full gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pelkat</p>
            <SelectInput
              option={PelkatOptions}
              name="statusPelkat"
              onChange={handleChange}
              value={formData.statusPelkat}
              required
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Status</p>
            <Input
              name="statusAktif"
              type="text"
              disabled={true}
              value={"Aktif"}
            />
          </div>
        </div>

      </div>

      <div className="w-full flex items-end justify-end p-5 pr-[50px]">
        <Button className="w-[100px] mx-5 my-2" type="submit">
          Next
        </Button>
      </div>
    </form>
  );
};

export default FormTambahJemaat;
