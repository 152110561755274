import ScrollContainer from "react-indiana-drag-scroll";
import Loader from "../../loader/Loader";

const Table = ({ columns, doSort, rows, load }) => {
  const rowsForTable = rows ? rows : [];
  return (
    <div className="max-w-full flex">
      {load ? (
        <div className="w-full p-5 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {rowsForTable.length === 0 ? (
            <p className=" text-center w-full bg-primary p-5">Data tidak ada</p>
          ) : (
            <>
              <ScrollContainer className="cursor-pointer w-full rounded-lg">
                <table className="rounded-tl-lg">
                  <thead className="w-[100%] rounded-tl-lg">
                    <tr className="px-12 py-12 w-[100%] whitespace-nowrap font-roboto-medium text-sm text-black-500 text-left bg-white-100 h-16 border-0 border-b-2 border-b-gray-1004">
                      {columns?.map(
                        (column, index) =>
                          index > 0 && (
                            <>
                              {column.label !== "Action" && (
                                <th
                                  key={index}
                                  className={` w-[100%] max-w-[200px] px-5 ${column.style}`}
                                >
                                  {(column.sort &&
                                    (column.typeSort === "asc" ? (
                                      <div
                                        onClick={() => doSort(column.field)}
                                        className=" flex cursor-pointer"
                                      >
                                        {column.label}
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => doSort(column.field)}
                                        className=" flex cursor-pointer"
                                      >
                                        {column.label}
                                      </div>
                                    ))) ||
                                    column.label}
                                </th>
                              )}
                            </>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {rowsForTable.length !== 0 &&
                      rowsForTable.map((row, indexRow) => (
                        <tr
                          key={indexRow}
                          className={` border-b-[1px] font-roboto-medium bg-white-100 border-gray-300 h-2 whitespace-nowrap hover:bg-gray-200`}
                        >
                          {columns?.map((column, columnIndex) => (
                            <>
                              {columnIndex > 0 && (
                                <>
                                  {column.label !== "Action" && (
                                    <td className={` px-5 py-2`}>
                                      {row[column.field]}
                                    </td>
                                  )}
                                </>
                              )}
                            </>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ScrollContainer>
              <table className=" shadow-md rounded-tr-lg">
                <thead>
                  {/* <tr className='px-12 py-12 whitespace-nowrap font-roboto-medium text-sm text-black-500 text-left bg-gray-300 h-16'> */}
                  {columns?.map(
                    (column, index) =>
                      column.field === "action" && (
                        <th
                          key={index}
                          className={`px-8 py-5 font-roboto-medium text-sm text-black-500 text-left bg-white-100 h-16 border-0 border-b-2 border-b-gray-1004 rounded-tr-lg ${index === columns.length - 1 && " rounded-tr-lg "
                            }`}
                        >
                          {(column.sort &&
                            (column.typeSort === "asc" ? (
                              <div
                                onClick={() => doSort(column.field)}
                                className=" flex cursor-pointer"
                              >
                                {column.label}
                              </div>
                            ) : (
                              <div
                                onClick={() => doSort(column.field)}
                                className=" flex cursor-pointer"
                              >
                                {column.label}
                              </div>
                            ))) ||
                            column.label}
                        </th>
                      )
                  )}
                  {/* </tr> */}
                </thead>
                <tbody>
                  {rowsForTable.length !== 0 &&
                    rowsForTable.map((row, indexRow) => (
                      <tr
                        key={indexRow}
                        className="border-b-[1px] font-roboto-medium text-gray-100 border-gray-300 bg-white-100"
                      >
                        {columns?.map(
                          (column, columnIndex) =>
                            column.field === "action" && (
                              <td className=" px-8 ">{row[column.field]}</td>
                            )
                        )}
                      </tr>
                    ))}
                </tbody>
                {rowsForTable.length === 0 && (
                  <p className=" text-center w-full"></p>
                )}
              </table>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
