const SmallCard = ({ title, data, color }) => {
  return (
    <div
      className={`w-[200px] h-fit text-gray-400 rounded-lg flex items-start justify-start py-5 px-2 gap-3 `}
      style={{ backgroundColor: color }}
    >
      <p className="font-bold">{data}</p>
      <p>{title}</p>
    </div>
  );
};

export default SmallCard;
