import { useEffect, useState } from "react";
import Stepper from "../../../components/stepper/Stepper";
import FormInformasiJemaat from "./FormInformasiJemaat";
import FormTambahJemaat from "./FormTambahJemaat";
import FormTanggalanJemaat from "./FormTanggalanJemaat";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PostAnggotaKeluarga } from "../../../service/jemaatService/JemaatServices";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constant/routeConstant";
import { sanitizeObject } from "../../../utils/sanitizer";

const TambahDataKeluarga = () => {
  const Confirmation = withReactContent(Swal);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [lastChild, setLastChild] = useState("");
  const getLastChild = () => {
    let result = parseInt(
      state?.data
        ?.filter((item) => item.hubunganKeluarga === "AN")
        ?.sort(
          (a, b) =>
            parseInt(b.noInduk.slice(15)) - parseInt(a.noInduk.slice(15))
        )[0]
        ?.noInduk.slice(15)
    );
    setLastChild(result);
  };
  console.log(lastChild);
  useEffect(() => {
    getLastChild();
  }, []);

  const [formData, setFormData] = useState({
    namaPertama: null,
    jenisKelamin: "l",
    noTelp: null,
    statusPelkat: null,
    namaBelakang: null,
    golonganDarah: null,
    email: null,
    sektor: state.sektor,
    statusAktif: "Aktif",
    tempat_lahir: null,
    tempatBaptis: null,
    tempatSidi: null,
    tglNikahGereja: null,
    tanggalLahir: null,
    tanggal_baptis: null,
    tanggalSidi: null,
    tglNikahSipil: null,
    pengalamanGerejawi: null,
    pendidikanTerakhir: null,
    pekerjaan: null,
    profesi: null,
    penguasaanBahasa: null,
    riwayatLain: null,
    pengalamanOrganisasi: null,
    jurusan: null,
    tempatKerja: null,
    posisiJabatan: null,
    kompetensiSkill: null,
    statusKeluarga: "",
  });
  const [menus, setMenus] = useState([
    {
      title: "Jemaat",
      status: "open",
    },
    {
      title: "Tanggalan",
      status: "closed",
    },
    {
      title: "Informasi",
      status: "closed",
    },
  ]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitKeluarga = async (finalData) => {
    let response = await PostAnggotaKeluarga(finalData);
    if (response.status) {
      toast.success(response.message);
      navigate(-1);
    } else {
      toast.error(response.message);
    }
  };

  const cekHubunganKeluarga = (data) => {
    if (data.toString().includes("2")) {
      return "IS";
    }
    if (data.toString().includes("3")) {
      return "AN";
    }
    if (data.toString().includes("8")) {
      return "OL";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Confirmation.fire({
      title: <p>Periksa kembali data anda</p>,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        const finalData = {
          ...formData,
          kodeKeluarga: state.kodeKeluarga,
          statusSidi: formData.tempatSidi ? "S" : "B",
          statusBaptis: formData.tempatBaptis ? "S" : "B",
          statusNikah: formData.tempatNikah ? "S" : "B",
          noTelp: null,
          hubunganKeluarga: cekHubunganKeluarga(formData.statusKeluarga),
          namaKeluarga: state.namaKeluarga,
          urutanKeluarga: state.data.length + 1,
          gelar: formData.pendidikanTerakhir,
        };
        submitKeluarga(sanitizeObject(finalData));
      }
    });
  };
  return (
    <div className="p-5">
      <p className="text-blue-500 text-[24px] font-semibold font-poppins mb-5">
        Tambah Keluarga
      </p>
      <div className="w-[90%] mb-[50px] h-fit border bg-white-100 rounded-lg">
        <Stepper menus={menus} />
        <hr />
        {menus.find((item) => item.title === "Jemaat").status === "open" && (
          <FormTambahJemaat
            onSubmit={() => {
              setMenus([
                {
                  title: "Jemaat",
                  status: "completed",
                },
                {
                  title: "Tanggalan",
                  status: "open",
                },
                {
                  title: "Informasi",
                  status: "closed",
                },
              ]);
            }}
            formData={formData}
            handleChange={handleChange}
            isTambahKeluarga
            kodeKeluarga={state.kodeKeluarga}
            lastChild={lastChild}
            changeStatusKeluarga={(e) =>
              setFormData({
                ...formData,
                noInduk: `${state.kodeKeluarga}-${e.target.value}`,
                statusKeluarga: e.target.value,
              })
            }
          />
        )}
        {menus.find((item) => item.title === "Tanggalan").status === "open" && (
          <FormTanggalanJemaat
            onSubmit={() => {
              setMenus([
                {
                  title: "Jemaat",
                  status: "completed",
                },
                {
                  title: "Tanggalan",
                  status: "completed",
                },
                {
                  title: "Informasi",
                  status: "open",
                },
              ]);
            }}
            formData={formData}
            handleChange={handleChange}
            onPrevious={() =>
              setMenus([
                {
                  title: "Jemaat",
                  status: "open",
                },
                {
                  title: "Tanggalan",
                  status: "closed",
                },
                {
                  title: "Informasi",
                  status: "closed",
                },
              ])
            }
          />
        )}
        {menus.find((item) => item.title === "Informasi").status === "open" && (
          <FormInformasiJemaat
            formData={formData}
            handleChange={handleChange}
            onSubmit={handleSubmit}
            onPrevious={() =>
              setMenus([
                {
                  title: "Jemaat",
                  status: "completed",
                },
                {
                  title: "Tanggalan",
                  status: "open",
                },
                {
                  title: "Informasi",
                  status: "closed",
                },
              ])
            }
          />
        )}
      </div>
    </div>
  );
};

export default TambahDataKeluarga;
