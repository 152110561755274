import Input from "../../../components/base/Input/Input";

const EditDataInformasi = ({ data, handleChange }) => {
  return (
    <form className=" w-full flex flex-col">

      <div className="w-full flex flex-col items-center justify-center gap-[20px] p-[40px]">

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pengalaman Gerejawi</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              value={data.pengalamanGerejawi}
              onChange={handleChange}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Pengalaman Organisasi</p>
            <Input
              name="pengalamanOrganisasi"
              type="text"
              value={data.pengalamanOrganisasi}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pendidikan Terakhir</p>
            <Input
              name="pendidikanTerakhir"
              type="text"
              value={data.pendidikanTerakhir}
              onChange={handleChange}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Jurusan</p>
            <Input
              name="jurusan"
              type="text"
              value={data.jurusan}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pekerjaan</p>
            <Input
              name="pekerjaan"
              type="text"
              value={data.pekerjaan}
              onChange={handleChange}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Kerja</p>
            <Input
              name="tempatKerja"
              type="text"
              value={data.tempatKerja}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Profesi</p>
            <Input
              name="profesi"
              type="text"
              value={data.profesi}
              onChange={handleChange}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Posisi Jabatan</p>
            <Input
              name="posisiJabatan"
              type="text"
              value={data.posisiJabatan}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Penguasaan Bahasa</p>
            <Input
              name="penguasaanBahasa"
              type="text"
              value={data.penguasaanBahasa}
              onChange={handleChange}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Kompetensi Skill</p>
            <Input
              name="kompetensiSkill"
              type="text"
              value={data.kompetensiSkill}
              onChange={handleChange}
            />
          </div>
        </div>


        <div className="w-full flex flex-row items-center justify-center gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Riwayat Lain</p>
            <Input
              name="riwayatLain"
              type="text"
              value={data.riwayatLain}
              onChange={handleChange}
            />
          </div>
          <div className="w-[90%]">

          </div>
        </div>
      </div>

    </form>
  );
};

export default EditDataInformasi;
