export const ROUTES = {
  LOGIN: "/",
  ADMIN: {
    DASHBOARD: "/database",
    DATA_KELUARGA: "/data-keluarga",
    DATA_INDUK: "/jemaat",
    JEMAAT: {
      EDIT: "/jemaat/edit",
      DETAIL: "/jemaat/detail",
      DETAIL_KELUARGA: "/data-keluarga/detail",
      TAMBAH_KELUARGA: "/data-keluarga/anggota/tambah",
      ADD: "/data-keluarga/tambah",
    },
  },
};
