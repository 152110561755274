import { Navigate } from "react-router-dom";
import MasterLayout from "../components/layout/MasterLayout";
import { ROUTES } from "../constant/routeConstant";

const PrivateRoute = ({ children }) => {
  const isToken = localStorage.getItem("token");
  if (isToken.length > 0) {
    return <MasterLayout>{children}</MasterLayout>;
  } else {
    return <Navigate to={ROUTES.LOGIN} />;
  }
};

export default PrivateRoute;
