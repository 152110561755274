import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";

const FormTanggalanJemaat = ({
  onSubmit,
  formData,
  handleChange,
  onPrevious,
}) => {
  return (
    <form className=" w-full flex flex-col" onSubmit={onSubmit}>

      <div className="w-full flex flex-col items-center justify-center gap-[20px] p-[40px]">

        <div className="w-full flex flex-row items-center justify-between gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Lahir</p>
            <Input
              name="tempat_lahir"
              type="text"
              onChange={handleChange}
              value={formData.tempat_lahir}
              required
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Lahir</p>
            <Input
              name="tanggalLahir"
              type="date"
              onChange={handleChange}
              value={formData.tanggalLahir}
              required
            />
          </div>

        </div>

        <div className="w-full flex flex-row items-center justify-between gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Baptis</p>
            <Input
              name="tempatBaptis"
              type="text"
              onChange={handleChange}
              value={formData.tempatBaptis}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Baptis</p>
            <Input
              name="tanggal_baptis"
              type="date"
              onChange={handleChange}
              value={formData.tanggal_baptis}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-between gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Sidi</p>
            <Input
              name="tempatSidi"
              type="text"
              onChange={handleChange}
              value={formData.tempatSidi}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Sidi</p>
            <Input
              name="tanggalSidi"
              type="date"
              onChange={handleChange}
              value={formData.tanggalSidi}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-between gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Nikah Gereja</p>
            <Input
              name="tglNikahGereja"
              type="date"
              onChange={handleChange}
              value={formData.tglNikahGereja}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Nikah Sipil</p>
            <Input
              name="tglNikahSipil"
              type="date"
              onChange={handleChange}
              value={formData.tglNikahSipil}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex items-end justify-end p-5 pr-[50px]">
        <Button className="w-[100px] my-2" type="button" onClick={onPrevious}>
          Previous
        </Button>
        <Button className="w-[100px] mx-5 my-2" type="submit">
          Next
        </Button>
      </div>
    </form>
  );
};

export default FormTanggalanJemaat;
