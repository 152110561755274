const Tabs = ({ tabsData, onClick }) => {
  const setActiveTabs = (index) => {
    let data = [...tabsData].map((item, indexItem) => {
      return {
        title: item.title,
        active: index === indexItem ? true : false,
      };
    });
    onClick(data);
  };
  return (
    <div className="flex flex-row items-start justify-start w-fit">
      {tabsData?.map((item, index) => {
        return (
          <div
            className={`w-fit ${
              item.active && "border-b-2 border-blue-500"
            }  pb-3 px-3`}
            onClick={() => setActiveTabs(index)}
            key={index}
          >
            <button
              className={`${
                item.active ? "text-blue-500 " : "text-gray-400"
              } font-light font-poppins text-[16px]`}
            >
              {item.title}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
