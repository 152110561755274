import { useState } from "react";
import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";

const FormInformasiJemaat = ({
  onSubmit,
  onPrevious,
  formData,
  handleChange,
}) => {
  return (
    <form className=" w-full flex flex-col" onSubmit={onSubmit}>
      <div className="w-full flex flex-col items-center justify-center p-[40px] gap-[20px]">

        <div className="w-full flex flex-row gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pengalaman Gerejawi</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              onChange={handleChange}
              value={formData.pengalamanGerejawi}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Pengalaman Organisasi</p>
            <Input
              name="pengalamanOrganisasi"
              type="text"
              onChange={handleChange}
              value={formData.pengalamanOrganisasi}
            />
          </div>
        </div>

        <div className="w-full flex flex-row gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pendidikan Terakhir</p>
            <Input
              name="pendidikanTerakhir"
              type="text"
              onChange={handleChange}
              value={formData.pendidikanTerakhir}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Jurusan</p>
            <Input
              name="jurusan"
              type="text"
              onChange={handleChange}
              value={formData.jurusan}
            />
          </div>
        </div>

        <div className="w-full flex flex-row gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Pekerjaan</p>
            <Input
              name="pekerjaan"
              type="text"
              onChange={handleChange}
              value={formData.pekerjaan}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Kerja</p>
            <Input
              name="tempatKerja"
              type="text"
              onChange={handleChange}
              value={formData.tempatKerja}
            />
          </div>
        </div>

        <div className="w-full flex flex-row gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Profesi</p>
            <Input
              name="profesi"
              type="text"
              onChange={handleChange}
              value={formData.profesi}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Posisi Jabatan</p>
            <Input
              name="posisiJabatan"
              type="text"
              onChange={handleChange}
              value={formData.posisiJabatan}
            />
          </div>
        </div>

        <div className="w-full flex flex-row gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Penguasaan Bahasa</p>
            <Input
              name="penguasaanBahasa"
              type="text"
              onChange={handleChange}
              value={formData.penguasaanBahasa}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Kompetensi Skill</p>
            <Input
              name="kompetensiSkill"
              type="text"
              onChange={handleChange}
              value={formData.kompetensiSkill}
            />
          </div>
        </div>

        <div className="w-full flex flex-row gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Riwayat Lain</p>
            <Input
              name="pengalamanGerejawi"
              type="text"
              onChange={handleChange}
              value={formData.riwayatLain}
            />
          </div>
          <div className="w-[90%]">

          </div>
        </div>
      </div>

      <div className="w-full flex items-end justify-end p-5 pr-[50px]">
        <Button className="w-[100px] my-2" type="button" onClick={onPrevious}>
          Previous
        </Button>
        <Button className="w-[100px] mx-5 my-2" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default FormInformasiJemaat;
