import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";
import Pagination from "../../../components/base/Table/Pagination";
import Table from "../../../components/base/Table/Table";
import {
  DeleteJemaat,
  GetJemaat,
} from "../../../service/jemaatService/JemaatServices";
import { columns, rows } from "./utils";
import Loader from "../../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { ROUTES } from "../../../constant/routeConstant";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useDebouncedCallback } from "use-debounce";

const DataInduk = () => {
  const Confirmation = withReactContent(Swal);

  const navigate = useNavigate();
  const [data, setData] = useState({
    data: [],
  });
  const [load, setLoad] = useState(false);

  const getData = async (data) => {
    setLoad(true);
    try {
      const response = await GetJemaat({
        data: data,
      });
      if (response.status) {
        setData(response.data);
      }
      setLoad(false);
    } catch (err) {
      setLoad(false);
      toast.error("Terjadi kesalahan pada sistem");
    }
  };

  useEffect(() => {
    getData({ page: 1, per_page: 10 });
  }, []);

  const handlePagination = (page) => {
    getData({ page: page, per_page: 10 });
  };

  const debounced = useDebouncedCallback((value) => {
    searchData(value);
  }, 500);

  const searchData = (e) => {
    setData({ data: [] });
    if (e.length > 0) {
      getData({ nama: e, per_page: 1000000 });
    } else {
      getData({ page: 1, per_page: 10 });
    }
  };

  const fetchDeleteData = async (request, id) => {
    let response = await DeleteJemaat(request, id);
    if (response.status) {
      toast.success(response.message);
      getData({ page: 1, per_page: 10 });
    } else {
      toast.error(response.message);
    }
  };

  const deleteData = (id) => {
    Confirmation.fire({
      title: <p>Apakah anda yakin ingin menghapus data ini?</p>,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        const request = {
          isDeleted: 1,
        };
        fetchDeleteData(request, id);
      }
    });
  };
  return (
    <div className="max-w-[95%] p-5 flex flex-col gap-5 pb-[100px]">
      <div className="flex flex-row gap-3 justify-between flex-wrap">
        <Input
          placeholder={"Cari data jemaat"}
          className="w-[70%] lg:w-[300px] bg-white-100"
          onChange={(e) => debounced(e.target.value)}
          prefix={<AiOutlineSearch className="ml-2" />}
        />
      </div>
      {load ? (
        <div className="w-full h-[40vh] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="w-full">
            {data?.data?.length > 0 ? (
              <>
                <Table
                  columns={columns}
                  rows={rows(data, navigate, deleteData)}
                />
                <Pagination
                  pagination={data}
                  handlePagination={handlePagination}
                />
              </>
            ) : (
              <div className="w-full h-[40vh] flex items-center justify-center">
                No Data
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DataInduk;
