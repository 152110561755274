import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Button from "../../../components/base/Button/Button";
import Tabs from "../../../components/tabs/Tabs";
import { ROUTES } from "../../../constant/routeConstant";
import { PutKeluarga } from "../../../service/jemaatService/JemaatServices";
import { sanitizeObject } from "../../../utils/sanitizer";
import EditDataInformasi from "./EditDataInformasi";
import EditDataJemaat from "./EditDataJemaat";
import EditDataTanggalan from "./EditDataTanggalan";
import moment from "moment";

const EditJemaat = () => {
  const Confirmation = withReactContent(Swal);
  const { state } = useLocation();
  const [formData, setFormData] = useState(state);
  const navigate = useNavigate();

  const [tabsData, setTabsData] = useState([
    {
      title: "Jemaat",
      active: true,
    },
    {
      title: "Tanggalan",
      active: false,
    },
    {
      title: "Informasi",
      active: false,
    },
  ]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const saveChanges = async (finalData) => {
    try {
      let response = await PutKeluarga(finalData);
      if (response.status) {
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      toast.error("System Error!");
    }
  };

  const handleSubmit = () => {
    Confirmation.fire({
      title: <p>Periksa kembali data anda</p>,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        const finalData = {
          ...formData,
          statusSidi: formData.tempatSidi ? "S" : "B",
          statusBaptis: formData.tempatBaptis ? "S" : "B",
          statusNikah: formData.tempatNikah ? "S" : "B",
          noTelp: null,
          gelar: formData.pendidikanTerakhir,
          hubunganKeluarga: formData.hubunganKeluarga,
          urutanKeluarga: formData.urutanKeluarga,
          tanggalLahir:
            formData.tanggalLahir === "0000-00-00 00:00:00"
              ? null
              : formData.tanggalLahir,
          tanggalSidi:
            formData.tanggalSidi === "0000-00-00 00:00:00"
              ? null
              : formData.tanggalSidi,
          tanggalBaptis:
            formData.tanggalBaptis === "0000-00-00 00:00:00"
              ? null
              : formData.tanggalBaptis,
          tglNikahGereja:
            formData.tglNikahGereja === "0000-00-00 00:00:00"
              ? null
              : formData.tglNikahGereja,
          tglNikahSipil:
            formData.tglNikahSipil === "0000-00-00 00:00:00"
              ? null
              : formData.tglNikahSipil,
          createdAt: moment().format("YYYY-MM-DD hh:mm:ss"),
          updatedAt: moment().format("YYYY-MM-DD hh:mm:ss"),
        };
        saveChanges(sanitizeObject(finalData));
      }
    });
  };
  return (
    <div className="p-5">
      <p className="text-blue-500 text-[24px] font-semibold font-poppins mb-5">
        Edit Jemaat
      </p>
      <Tabs tabsData={tabsData} onClick={(payload) => setTabsData(payload)} />
      <div className="w-full bg-white-100 mt-5 border rounded-lg">
        {tabsData.find((item) => item.title === "Jemaat").active && (
          <EditDataJemaat data={formData} handleChange={handleChange} />
        )}
        {tabsData.find((item) => item.title === "Tanggalan").active && (
          <EditDataTanggalan data={formData} handleChange={handleChange} />
        )}
        {tabsData.find((item) => item.title === "Informasi").active && (
          <EditDataInformasi data={formData} handleChange={handleChange} />
        )}
      </div>
      <div className="w-full flex items-end justify-end ml-[20px] mb-[30px]">
        <Button
          className="w-fit mx-5 my-2"
          type="button"
          onClick={() => handleSubmit()}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default EditJemaat;
