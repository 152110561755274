import Input from "../Input/Input";

const Pagination = ({ pagination, handlePagination }) => {
  const prev = () => {
    handlePagination(parseInt(pagination.current_page) - 1);
  };

  const next = () => {
    handlePagination(parseInt(pagination.current_page) + 1);
  };
  return (
    <>
      {pagination.current_page && (
        <>
          <div className="w-full items-center justify-between p-2 flex gap-[10px] bg-white-100 rounded-b-lg ">
            <button
              className="p-2 py-1 rounded-lg border border-gray-300 bg-[#F5F5F5] px-5"
              onClick={prev}
              disabled={parseInt(pagination.current_page) === 0}
            >
              Prev
            </button>
            <div className="flex flex-row gap-[10px] items-center">
              <p>{`${parseInt(pagination.current_page)} of ${
                pagination.last_page
              }`}</p>
            </div>
            <button
              className="p-2 py-1 rounded-lg border border-gray-300 bg-[#F5F5F5] px-5"
              onClick={next}
              disabled={
                parseInt(pagination.current_page) === pagination.lastPage
              }
            >
              Next
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Pagination;
