import Input from "../../../components/base/Input/Input";
import SelectInput from "../../../components/base/Input/SelectInput";

const DetailDataJemaat = ({ data }) => {
  const PelkatOptions = [
    {
      value: "GP",
      label: "GP",
    },
    {
      value: "PKP",
      label: "PKP",
    },
    {
      value: "PKB",
      label: "PKB",
    },
    {
      value: "PA",
      label: "PA",
    },
    {
      value: "PT",
      label: "PT",
    },
    {
      value: "PKLU",
      label: "PKLU",
    },
  ];

  const SektorOptions = [
    {
      value: "Yerusalem",
      label: "Yerusalem",
    },
    {
      value: "Betel",
      label: "Betel",
    },
    {
      value: "Hermon",
      label: "Hermon",
    },
    {
      value: "Sion",
      label: "Sion",
    },
    {
      value: "Yerikho",
      label: "Yerikho",
    },
    {
      value: "Karmel",
      label: "Karmel",
    },
    {
      value: "Pniel",
      label: "Pniel",
    },
    {
      value: "Nazaret",
      label: "Nazaret",
    },
    {
      value: "Moria",
      label: "Moria",
    },
    {
      value: "Getsemani",
      label: "Getsemani",
    },
    {
      value: "Bethesda",
      label: "Bethesda",
    },
    {
      value: "Betlehem",
      label: "Betlehem",
    },
    {
      value: "Ebenhaezer",
      label: "Ebenhaezer",
    },
  ];
  return (
    <form className=" w-full flex flex-col">
      <div className="p-5 grid grid-cols-2 gap-4">
        <div className="w-full flex flex-col items-start justify-start gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Nama Pertama</p>
            <Input
              name="namaPertama"
              type="text"
              disabled
              value={data.namaPertama}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Jenis Kelamin</p>
            <SelectInput
              option={[
                { value: "l", label: "Laki-Laki" },
                { value: "p", label: "Perempuan" },
              ]}
              value={data.jenisKelamin}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">No Telp</p>
            <Input name="alamat" type="text" disabled value={data.noTelp} />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Alamat</p>
            <Input name="alamat" type="text" disabled value={"-"} />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Pelkat</p>
            <SelectInput
              option={PelkatOptions}
              disabled
              value={data.statusPelkat}
            />
          </div>
        </div>

        <div className="w-full flex flex-col items-start justify-start gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Nama Belakang</p>
            <Input
              name="namaBelakang"
              type="text"
              value={data.namaBelakang}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Golongan Darah</p>
            <Input
              name="pengalamanOrganisasi"
              type="text"
              disabled
              value={data.golonganDarah}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Email</p>
            <Input
              name="pengalamanOrganisasi"
              type="text"
              value={data.email}
              disabled
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Sektor</p>
            <SelectInput
              option={SektorOptions}
              name="sektor"
              disabled
              value={data.sektor}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Status</p>
            <Input
              name="status"
              type="text"
              disabled={true}
              value={data.status}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default DetailDataJemaat;
