import { useRef, useState } from "react";
import Button from "../../base/Button/Button";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import useClickOutside from "../../../utils/useClickOutside";
import { LogoGpib } from "../../../assets";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constant/routeConstant";
import { MENU_DATA } from "../../../constant/menuConstant";

const Navbar = () => {
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const sidebarRef = useRef(null);
  const profileTopRef = useRef(null);

  useClickOutside(sidebarRef, () => setOpenSidebar(false));
  useClickOutside(profileTopRef, () => setOpenDropdown(false));

  return (
    <>
      <div className="w-full bg-white-100 h-[50px] shadow-sm flex flex-row items-center justify-between">
        <p className="pl-5 font-semibold font-poppins text-[24px] text-blue-500">
          GPIB Pancaran Kasih
        </p>
        <div className="absolute top-1 right-0 pr-5">
          <div
            className={`relative items-center gap-2 hover:bg-gray-100 p-[8px] rounded-[10px] cursor-pointer lg:block hidden`}
            ref={profileTopRef}
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <button className="flex flex-row items-center justify-center gap-1">
              <CgProfile className="cursor-pointer" fontSize={"24px"} />
              <span className="font-sans font-semibold text-black-300 text-[14px]">
                {localStorage.getItem("name")}
              </span>
            </button>
            {openDropdown && (
              <ul className="absolute top-[35px] right-[0px] text-gray-700 bg-white-100 w-[175px] box-border p-[10px] flex flex-col gap-3 shadow-lg shadow-black-500/50 rounded-[10px] z-10">
                <li className="w-full h-auto hover:bg-pink-100 pl-[15px] rounded-[10px] font-sans font-semibold text-black-300">
                  <button
                    className="w-full text-left flex items-center text-red-100"
                    onClick={() => {
                      navigate(ROUTES.LOGIN);
                      localStorage.setItem("token", "");
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            )}
          </div>

          {!openSidebar ? (
            <Button
              className="px-2 py-5 mr-[-10px] h-[10px] block lg:hidden"
              onClick={() => setOpenSidebar(true)}
            >
              <GiHamburgerMenu />
            </Button>
          ) : (
            <Button
              className="px-2 py-5 mr-[-10px] h-[10px] block lg:hidden "
              onClick={() => setOpenSidebar(false)}
            >
              <AiOutlineClose className="hover:animate-spin" />
            </Button>
          )}
        </div>
      </div>

      {/* Sidebar for mobile */}
      <div
        className={`lg:hidden top-0 left-0 w-[90vw] max-w-[300px] bg-white-100 shadow-md text-white fixed h-full pt-5 z-40 ease-in-out duration-300 ${
          openSidebar ? "translate-x-0 " : "translate-x-[-90vw]"
        }`}
        ref={sidebarRef}
      >
        <img src={LogoGpib} alt="GPIB" className="w-[70%] m-auto" />
        <hr className="mt-2" />
        <div className="w-full flex flex-col items-start justify-start mt-2 gap-2">
          {MENU_DATA.map((item) => {
            return (
              <button
                className="pl-5 py-2 w-full font-bold hover:bg-gray-100"
                onClick={() => navigate(item.path)}
              >
                <p className="text-left text-black-100">{item.title}</p>
              </button>
            );
          })}
          <button
            className="pl-5 py-2 font-bold w-full text-left flex items-center text-red-100"
            onClick={() => {
              navigate(ROUTES.LOGIN);
              localStorage.setItem("token", "");
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
