import moment from "moment";
import Input from "../../../components/base/Input/Input";

const DetailDataTanggalan = ({ data }) => {
  console.log(data);
  return (
    <form className=" w-full flex flex-col">
      <div className="p-5 grid grid-cols-2 gap-4">
        <div className="w-full flex flex-col items-start justify-start gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Lahir</p>
            <Input
              disabled
              name="namaPertama"
              type="text"
              value={data.tempatLahir}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Baptis</p>
            <Input
              disabled
              name="alamat"
              type="text"
              value={data.tempatBaptis}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tempat Sidi</p>
            <Input disabled name="alamat" type="text" value={data.tempatSidi} />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Nikah Gereja</p>
            <Input
              disabled
              name="namaBelakang"
              type="date"
              value={moment(data.tglNikahGereja).format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-[20px]">
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Lahir</p>
            <Input
              disabled
              name="namaBelakang"
              type="date"
              value={moment(data.tanggalLahir).format("YYYY-MM-DD")}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Baptis</p>
            <Input
              disabled
              name="namaBelakang"
              type="date"
              value={moment(data.tanggalBaptis).format("YYYY-MM-DD")}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Sidi</p>
            <Input
              disabled
              name="namaBelakang"
              type="date"
              value={moment(data.tanggalSidi).format("YYYY-MM-DD")}
            />
          </div>
          <div className="w-[90%]">
            <p className="font-semibold">Tanggal Nikah Sipil</p>
            <Input
              disabled
              name="namaBelakang"
              type="date"
              value={moment(data.tglNikahSipil).format("YYYY-MM-DD")}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default DetailDataTanggalan;
