import { useState } from "react";
import Button from "../../../components/base/Button/Button";
import Input from "../../../components/base/Input/Input";
import Stepper from "../../../components/stepper/Stepper";
import FormInformasiJemaat from "./FormInformasiJemaat";
import FormTambahJemaat from "./FormTambahJemaat";
import FormTanggalanJemaat from "./FormTanggalanJemaat";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PostKeluarga } from "../../../service/jemaatService/JemaatServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constant/routeConstant";
import moment from "moment";
import { sanitizeObject } from "../../../utils/sanitizer";

const TambahJemaat = () => {
  const Confirmation = withReactContent(Swal);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    namaPertama: null,
    jenisKelamin: "l",
    noTelp: null,
    statusPelkat: null,
    namaBelakang: null,
    golonganDarah: null,
    email: null,
    sektor: null,
    statusAktif: "Aktif",
    tempat_lahir: null,
    tempatBaptis: null,
    tempatSidi: null,
    tglNikahGereja: null,
    tanggalLahir: null,
    tanggal_baptis: null,
    tanggalSidi: null,
    tglNikahSipil: null,
    pengalamanGerejawi: null,
    pendidikanTerakhir: null,
    pekerjaan: null,
    profesi: null,
    penguasaanBahasa: null,
    riwayatLain: null,
    pengalamanOrganisasi: null,
    jurusan: null,
    tempatKerja: null,
    posisiJabatan: null,
    kompetensiSkill: null,
  });
  const [menus, setMenus] = useState([
    {
      title: "Jemaat",
      status: "open",
    },
    {
      title: "Tanggalan",
      status: "closed",
    },
    {
      title: "Informasi",
      status: "closed",
    },
  ]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitKeluarga = async (finalData) => {
    try {
      let response = await PostKeluarga(finalData);
      if (response.status) {
        toast.success(response.message);
        navigate(ROUTES.ADMIN.DASHBOARD, { state: 3 });
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      toast.error("System Error!");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Confirmation.fire({
      title: <p>Periksa kembali data anda</p>,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        const finalData = {
          ...formData,
          statusSidi: formData.tempatSidi ? "S" : "B",
          statusBaptis: formData.tempatBaptis ? "S" : "B",
          statusNikah: formData.tempatNikah ? "S" : "B",
          noTelp: null,
          gelar: formData.pendidikanTerakhir,
          hubunganKeluarga: "KK",
          urutanKeluarga: "1",
        };
        submitKeluarga(sanitizeObject(finalData));
      }
    });
  };
  return (
    <div className="p-5">
      <p className="text-blue-500 text-[24px] font-semibold font-poppins mb-5">
        Tambah Keluarga
      </p>
      <div className="w-[90%] mb-[50px] h-fit border bg-white-100 rounded-lg">
        <Stepper menus={menus} />
        <hr />
        {menus.find((item) => item.title === "Jemaat").status === "open" && (
          <FormTambahJemaat
            onSubmit={() => {
              setMenus([
                {
                  title: "Jemaat",
                  status: "completed",
                },
                {
                  title: "Tanggalan",
                  status: "open",
                },
                {
                  title: "Informasi",
                  status: "closed",
                },
              ]);
            }}
            formData={formData}
            handleChange={handleChange}
          />
        )}
        {menus.find((item) => item.title === "Tanggalan").status === "open" && (
          <FormTanggalanJemaat
            onSubmit={() => {
              setMenus([
                {
                  title: "Jemaat",
                  status: "completed",
                },
                {
                  title: "Tanggalan",
                  status: "completed",
                },
                {
                  title: "Informasi",
                  status: "open",
                },
              ]);
            }}
            formData={formData}
            handleChange={handleChange}
            onPrevious={() =>
              setMenus([
                {
                  title: "Jemaat",
                  status: "open",
                },
                {
                  title: "Tanggalan",
                  status: "closed",
                },
                {
                  title: "Informasi",
                  status: "closed",
                },
              ])
            }
          />
        )}
        {menus.find((item) => item.title === "Informasi").status === "open" && (
          <FormInformasiJemaat
            formData={formData}
            handleChange={handleChange}
            onSubmit={handleSubmit}
            onPrevious={() =>
              setMenus([
                {
                  title: "Jemaat",
                  status: "completed",
                },
                {
                  title: "Tanggalan",
                  status: "open",
                },
                {
                  title: "Informasi",
                  status: "closed",
                },
              ])
            }
          />
        )}
      </div>
    </div>
  );
};

export default TambahJemaat;
