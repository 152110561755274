import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ROUTES } from "../constant/routeConstant";
import PrivateRoute from "../routes/PrivateRoutes";
import Login from "./public/login/Login";
import "react-toastify/dist/ReactToastify.css";
import DetailJemaat from "./admin/jemaat/DetailJemaat";
import TambahJemaat from "./admin/jemaat/TambahJemaat";
import EditJemaat from "./admin/jemaat/EditJemaat";
import DetailKeluarga from "./admin/jemaat/DetailKeluarga";
import Database from "./admin/database/Database";
import TambahDataKeluarga from "./admin/jemaat/TambahDataKeluarga";
import Dashboard from "./admin/database/Dashboard";
import DataKeluarga from "./admin/database/DataKeluarga";
import DataInduk from "./admin/database/DataInduk";

const Main = () => {
  return (
    <div className="w-[100vw] h-[100vh] max-w-[100vw] max-h-[100vh] overflow-hidden">
      <ToastContainer />
      <Routes>
        <Route element={<Login />} path={ROUTES.LOGIN} exact />
        <Route
          element={<PrivateRoute children={<Dashboard />} />}
          path={ROUTES.ADMIN.DASHBOARD}
          exact
        />
        <Route
          element={<PrivateRoute children={<DataKeluarga />} />}
          path={ROUTES.ADMIN.DATA_KELUARGA}
          exact
        />
        <Route
          element={<PrivateRoute children={<DataInduk />} />}
          path={ROUTES.ADMIN.DATA_INDUK}
          exact
        />
        <Route
          element={<PrivateRoute children={<Dashboard />} />}
          path={ROUTES.ADMIN.DASHBOARD}
          exact
        />
        <Route
          element={<PrivateRoute children={<DetailJemaat />} />}
          path={ROUTES.ADMIN.JEMAAT.DETAIL}
          exact
        />
        <Route
          element={<PrivateRoute children={<DetailKeluarga />} />}
          path={ROUTES.ADMIN.JEMAAT.DETAIL_KELUARGA}
          exact
        />
        <Route
          element={<PrivateRoute children={<TambahDataKeluarga />} />}
          path={ROUTES.ADMIN.JEMAAT.TAMBAH_KELUARGA}
          exact
        />
        <Route
          element={<PrivateRoute children={<TambahJemaat />} />}
          path={ROUTES.ADMIN.JEMAAT.ADD}
          exact
        />
        <Route
          element={<PrivateRoute children={<EditJemaat />} />}
          path={ROUTES.ADMIN.JEMAAT.EDIT}
          exact
        />
      </Routes>
    </div>
  );
};
export default Main;
